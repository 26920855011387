/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import * as THREE from "three";

const audio = new Audio("/audio2.mp3");

export default function Beer(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF(
        "/mrfydice.glb"
    );



    const { actions } = useAnimations(animations, group);
      const animationsArray = Object.keys(actions);
    React.useEffect(() => {
       // actions && actions['NlaTrack'].play();
    }, [actions]);


    const playRandomAnimation = () => {
        // Ensure no animation is currently playing
        const isAnyAnimationPlaying = Object.values(actions).some(action => action.isRunning());

        if (!isAnyAnimationPlaying) {
            audio.currentTime = 0;
            // Stop all animations to ensure a clean state
            Object.values(actions).forEach(action => action.stop());

            // Select and play a random animation
            const animationsArray = Object.keys(actions);
            const randomAnimation = animationsArray[Math.floor(Math.random() * animationsArray.length)];
            console.log("randomAnimation: ", randomAnimation);
            const action = actions[randomAnimation];
            action.reset();
            action.play();
            audio.play();
            action.clampWhenFinished = true;
            action.loop = THREE.LoopOnce;

            // Speed up the animation - adjust this value as needed
            action.timeScale = 5; // This will play the animation at double speed
        }
    };


    return (
        <group ref={group} onClick={()=>playRandomAnimation()} {...props} dispose={null}>
            <group name="Scene">
                <group name="Empty">
                    <mesh
                        name="Cube"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube.geometry}
                        material={materials.Material}
                        scale={0.3}
                    />
                </group>
            </group>
            </group>
    );
}

useGLTF.preload("/mrfydice.glb");