import { Canvas } from '@react-three/fiber';
import React from "react";
import { OrbitControls, Stage, PerspectiveCamera, Loader} from "@react-three/drei";
import { Suspense } from "react";
import Beer from "./models/Beer";
import logo from './logo.svg';
import './App.css';


function App() {
    const ref = React.useRef()
    const cameraRef = React.useRef();

    return (
        <div className="App">
            {/*<img className="Logo" src={logo} alt="logo" />*/}
                <Canvas dpr={[1, 2]} camera={{ fov: 50, position: [0, -0, 0] }} >

                    <PerspectiveCamera  ref={cameraRef}   fov={10} position={[-1, 6, 5]} makeDefault />
                    <Suspense fallback={null}>
                        <Stage adjustCamera={false} controls={ref} preset="portrait" intensity={0}    shadows={false} environment="apartment">
                            <group position={[0, 2, 0]}>
                                <Beer />
                            </group>
                        </Stage>
                    </Suspense>
                    <OrbitControls autoRotate={true} enableTouchScroll={true} maxDistance={80}   enableRotate={false} minDistance={2} enableZoom={true} enablePan={false} target={[0, 0, 0]} />
                </Canvas>
                <Loader />
        </div>
    );
}

export default App;
